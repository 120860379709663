const colors = {
  white: {
    shade1: '#fff',
    shade2: '#f5f5f5',
    shade3: 'fdfdfd',
  },
  red: {
    shade1: '#e4022d',
    shade2: '#eb0029',
    shade3: '#f44545',
    shade4: '#D60303',
    shade5: '#EA5E73',
  },
  orange: {
    shade1: '#ffc000',
    shade2: '#FF6200',
    shade3: 'rgb(255, 113, 0)',
    shade4: '#FF5400',
  },
  blue: {
    shade1: '#0000ee',
    shade2: '#4d6fa9',
    shade3: '#495867',
  },
  green: {
    shade1: '#8fca00',
    shade2: '#aad500',
    shade3: '#a9d100',
    shade4: '#8dc600',
    shade5: '#71b300',
    shade6: '#a4d41e',
    shade7: '#fefff8',
    shade8: '#e3ff57',
    shade9: '#93c900',
    shade10: '#6B8E23',
    shade11: '#9BC930',
    shade12: '#8eca00',
    shade13: '#f9fcf3',
    shade14: '#8dcb00',
    shade15: '#74B700',
    shade16: '#85c00114',
    shade17: '#62ba46',
    shade18: '#b3d341',
    shade19: '#99C33C',
    shade20: '#30871F',
    shade21: '#F3F9E5',
    shade22: '#186F07',
    shade23: '#D7F0D9',
  },
  black: {
    shade1: '#333',
    shade3: '#000',
    shade4: '#222',
    shade5: '#171717',
    shade6: '#666',
    shade7: '#888',
    shade8: '#3d3d3d',
    shade9: '#343433',
    shade10: '#333333',
    shade11: '#013F48',
    shade12: '#909294',
  },
  gray: {
    shade1: '#e9e9e9',
    shade2: '#e0e0e0',
    shade3: '#f8f8f8',
    shade4: '#1a1a1a',
    shade5: '#bbb',
    shade6: '#eee',
    shade7: '#ececec',
    shade8: '#7e7e7e',
    shade9: '#e5e5e5',
    shade10: '#999',
    shade11: '#303030',
    shade12: '#f9f9f9',
    shade13: '#eee',
    shade14: '#f0f0f0',
    shade15: '#333',
    shade16: '#616161',
    shade17: '#dedede',
    shade18: '#6b6b6b',
    shade19: '#888',
    shade20: '#222',
    shade21: '#2a2a2a',
    shade22: '#4c4c4c',
    shade23: '#2e2e2e',
    shade24: '#a9a9a9',
    shade25: '#bababa',
    shade26: '#f7f7f7',
    shade27: '#a9a9a9',
    shade28: '#e3e3e3',
    shade29: '#9b9b9b',
    shade30: '#908f96',
    shade31: '#cfcfcf',
    shade32: '#fafafa',
    shade33: '#cccccc',
    shade34: '#c0c0c0',
    shade35: '#d2d2d2',
    shade36: '#e6e6e6',
    shade37: '#ddd',
    shade38: '#9d9d9d',
    shade39: '#979797',
    shade40: '#4A4A4A',
    shade41: '#FBFBFB',
    shade42: '#c1c1c1',
    shade43: '#ededed',
    shade44: '#767676',
    shade45: '#495867',
    shade46: '#E1E1E1',
    shade47: '#636363',
    shade48: '#68717D',
    shade50: '#e2e2e2',
    shade51: '#fbfbfb',
    shade52: '#717171',
    shade53: '#8b8b8b',
    shade54: '#d1d1d1',
    shade55: '#A9B5C0',
    shade56: '#D9D9D9',
    shade57: '#F1F1F1',
  },
  socialIcons: {
    facebook: '#5479bd',
    twitter: '#55acee',
    instagram: '#e1306c',
    youtube: '#ce1312',
    pinterest: '#bd081c',
    email: '#BBBBBB',
    whatsapp: '#4CAF50',
  },
  warning: '#ee4b4b',
  yellow: {
    shade1: '#ecdb69',
    shade2: '#ffbf05',
    shade3: '#fec200',
    shade4: '#fff9e9',
    shade5: '#FFF6DC',
  },
  teal: {
    shade1: '#07757E',
    shade2: '#0B5D64',
  },
};

export const renderColorsAsCssVariables = () => {
  let cssVariables = '';

  Object.keys(colors).forEach((color) => {
    if (typeof colors[color] === 'string') {
      cssVariables += `--${color}: ${colors[color]};\n`;
      return;
    }
    Object.keys(colors[color]).forEach((shade) => {
      const variableName = `--${color}-${shade}`;
      const variableValue = colors[color][shade];
      cssVariables += `${variableName}: ${variableValue};\n`;
    });
  });

  return `
    :root {
      ${cssVariables}
    }
  `;
};

export default colors;
